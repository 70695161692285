import * as React from "react";

import { Helmet } from "react-helmet";

import PageLayout from "../components/PageLayout";
import Module from "../components/Module";

import tcx1Manual from '../assets/CM_TCorp.pdf';

import ManualImage from "../images/iStock-849636584.jpg";
import EmailImage from "../images/iStock-1195003906.jpg";

// import { StaticImage } from "gatsby-plugin-image"
import { Button } from "react-bootstrap";

const AboutPage = () => {
  return (
    <PageLayout>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}>
        <title>Tayos Corp - Sensor &amp; Software Manuals and Help Documents</title>
      </Helmet>
      <Module image={ManualImage} imageAlt="Close up White book marked by sticky note stock photo">
        <h1 className="h2">TC-X1 Manual</h1>
        <p>
          Download and read the technical menu for the TC-X1:<br />
          <Button href={tcx1Manual} variant="link">Download TC-X1 Manual</Button>
        </p>
      </Module>
      <Module image={EmailImage} imageAlt="Email background concept image" right>
        <h2>Need More Help?</h2>
        <p>
          Our team has world-class experience in cutting-edge physics, mathematics, electronics, and computer science.  Let us help you understand better how to use the 
          TC-X1 or any other Tayos technology today.<br /><br />
          <Button href="/contact/?template=help" variant="secondary">Get Help</Button>
          <Button href="/about/" variant="link">Meet Our Team</Button>
        </p>
      </Module>
    </PageLayout>
  )
}

export default AboutPage
